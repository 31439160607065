import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlmService } from 'src/app/shared/services/alm.service';
import { EnrollmentDetailsComponent } from '../enrollmentDetails/enrollmentDetails.component';

@Component({
  selector: 'app-enrollment',
  standalone: true,
  imports: [
    CommonModule,
    EnrollmentDetailsComponent
  ],
  templateUrl: './enrollment.component.html',
  styleUrl: './enrollment.component.scss'
})
export class EnrollmentComponent implements OnInit {
  public enrollments: any;
  public id: string = "";
  public type: string = "";
  public enrollmentDetails = new Map();
  public selectedEnrollment = '';

  public constructor(public almService: AlmService, private route: ActivatedRoute) { }

  public async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = "" + params.get('id');
      this.type = "" + params.get('type');
    })
    this.enrollments = await this.almService.getEnrollments(this.id, this.type);

    for (let i = 0; i < this.enrollments.length; i++) {
      this.enrollmentDetails.set(this.enrollments[i].id, await this.almService.deepload(this.enrollments[i].relationships.learningObject.data.id));
    }
    console.log(this.enrollmentDetails)
  }
}
