import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxLayoutModule, OverlayService } from '@reflact/ngx-layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlmService } from 'src/app/shared/services/alm.service';

export type Course = {
  id: string,
  name: string
}

export type CertificateConfig = {
  certId: string,
  name: string,
  courseBundles: Course[][]
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgMultiSelectDropDownModule,
    NgxLayoutModule,
    FormsModule,
    NgSelectModule,
    TooltipModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  public selectedCertificate?: CertificateConfig = undefined;
  public configurations: CertificateConfig[] = [];
  public certifications: any[] = [];
  public selectableCertifications: any[] = [];
  public courses: any[] = [];

  public constructor(public almService: AlmService, public overlayService: OverlayService) { }

  public async ngOnInit() {
    this.configurations = await this.almService.getCertificateConfigs();
    this.certifications = await this.almService.getLearningObjects('certification');
    this.updateSelectableCerts();
    this.courses = await this.almService.getLearningObjects('course');
  }

  private updateSelectableCerts() {
    this.selectableCertifications = this.certifications.filter(c => !this.configurations.some((config: any) => config.certId === c.id))
  }

  protected certChange(id: string) {
    if (id === '') return;
    this.selectedCertificate!.name = this.certifications.find(cert => cert.id === id).attributes.localizedMetadata[0].name;
  }

  protected async updateCertificateConfig() {
    await this.almService.updateCertificateConfig(this.selectedCertificate);
    this.configurations = await this.almService.getCertificateConfigs();
    this.updateSelectableCerts();
  }
  protected async deleteCertificateConfig(id: string) {
    await this.almService.deleteCertificateConfig(id);
    this.configurations = await this.almService.getCertificateConfigs();
    this.updateSelectableCerts();
  }

  protected compareFn = (a: any, b: any) => {
    return a.id === b.id;
  }

  protected getCourseNames(bundle: Course[]) {
    return bundle.map(course => course.name);
  }
}
