import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlmService } from 'src/app/shared/services/alm.service';

@Component({
  selector: 'app-enrollment-details',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './enrollmentDetails.component.html',
  styleUrl: './enrollmentDetails.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollmentDetailsComponent implements AfterViewInit {
  @Input() userId: string = '';
  @Input() enrollmentDetails: any;

  constructor(public almService: AlmService) { }

  ngAfterViewInit(): void {
    console.log(this.enrollmentDetails)
  }

  public updateUserModuleGrade(courseId: string, moduleId: string) {
    this.almService.updateUserModuleGrade(this.userId, courseId, moduleId);
  }
}
