<h2>{{this.enrollmentDetails.learningObject.data.attributes.localizedMetadata[0].name}}</h2>

<h3>Kurse</h3>

<div class="row">
  <div
    class="col col-3 m-1"
    *ngFor="let course of this.enrollmentDetails.subLoOItems"
  >
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          {{ course.learningObject.attributes.localizedMetadata[0].name}}
          ({{ course.learningObject.id }})
        </div>

        <pre>{{course|json}}</pre>
      </div>
    </div>
  </div>
</div>

<h3>Inhalte</h3>

<div class="row">
  <div
    class="col col-3 m-1"
    *ngFor="let included of this.enrollmentDetails.learningObject.included"
  >
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          {{ included.attributes.localizedMetadata[0].name }}
          ({{ included.type }}, {{ included.id }})
        </div>

        <button
          class="btn btn-primary"
          (click)="this.updateUserModuleGrade(this.enrollmentDetails.learningObject.data.id, included.id)"
        >Abschließen</button>

        <pre>{{included|json}}</pre>
      </div>
    </div>
  </div>