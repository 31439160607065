<h1>Enrollment - Typ <i>{{ type }}</i></h1>

<div
  *ngIf="this.selectedEnrollment != ''"
  class="row p-1"
>
  <div class="col-auto mb-2">
    <div
      class="btn btn-primary"
      (click)="this.selectedEnrollment = ''"
    >Zurück</div>
  </div>
  <div class="col-12">
    <app-enrollment-details
      [userId]="this.id"
      [enrollmentDetails]="this.enrollmentDetails.get(this.selectedEnrollment)"
    >
    </app-enrollment-details>
  </div>
</div>

<div
  *ngIf="this.selectedEnrollment == ''"
  class="row"
>
  <div
    class="col-3"
    *ngFor="let enrollment of enrollments"
  >
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{
          this.enrollmentDetails.get(enrollment.id).learningObject.data.attributes.localizedMetadata[0].name}}
          ({{ enrollment.id }})
          <i
            class="ri ri-information-line cursor-pointer"
            (click)="this.selectedEnrollment = enrollment.id"
          ></i>
        </h5>
        <p class="card-text m-0">state: {{ enrollment.attributes.state }}</p>
        <p class="card-text m-0">passed: {{ enrollment.attributes.hasPassed }}</p>
        <p class="card-text m-0">progress: {{ enrollment.attributes.progressPercent }}</p>
        <p class="card-text m-0">score: {{ enrollment.attributes.score }}</p>
        <p
          class="card-text m-0"
          *ngIf="enrollment.attributes.rating"
        >rating: {{ enrollment.attributes.rating }}</p>
      </div>
    </div>
  </div>
</div>