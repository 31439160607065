<button
    class="btn btn-primary mb-1"
    (click)="newCockpitOverlay.setSelected({'cert': 'new'}, 'cert'); this.selectedCertificate = {
        certId: '',
        name: '',
        courseBundles: [
            []
        ]
    }"
>Neues Cockpit</button>

<rag-overlay-aside #newCockpitOverlay>
    <div
        class="p-5"
        *ngIf="this.selectedCertificate && newCockpitOverlay.selectedObject"
    >
        <ng-container
            *ngIf="this.selectableCertifications.length == 0 && newCockpitOverlay.selectedObject.cert == 'new'"
        >
            <small class="text-danger">No more certificates avaliable!</small>
            <hr>
        </ng-container>
        <b>Certificate</b>
        <select
            class="form-control"
            [disabled]="newCockpitOverlay.selectedObject.cert != 'new'"
            [(ngModel)]="this.selectedCertificate!.certId"
            (ngModelChange)="this.certChange($event)"
        >
            <option [value]="''">Please Select</option>
            <option
                *ngFor="let certification of newCockpitOverlay.selectedObject.cert != 'new' ? this.certifications : this.selectableCertifications"
                [value]="certification.id"
            >
                {{certification.attributes.localizedMetadata[0].name}} - {{certification.id}}
            </option>
        </select>
        <ng-container *ngIf="this.selectedCertificate!.certId != ''">
            <div class="card mt-3">
                <div class="card-body">
                    <ng-container *ngFor="let courseBundle of this.selectedCertificate!.courseBundles; let i = index">
                        <hr *ngIf="i != 0">
                        <b>Course Bundle {{i+1}}</b>
                        <div class="row my-2 justify-content-center align-items-center">
                            <div class="col">
                                <ng-select
                                    [multiple]="true"
                                    [(ngModel)]="this.selectedCertificate!.courseBundles[i]"
                                    [compareWith]="compareFn"
                                    [closeOnSelect]="false"
                                >
                                    <ng-option
                                        *ngFor="let course of this.courses"
                                        [value]="{id: course.id, name: course.attributes.localizedMetadata[0].name}"
                                    >
                                        <div>
                                            <b>{{course.attributes.localizedMetadata[0].name}}</b><br><small>{{course.id}}</small>
                                        </div>
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-auto">
                                <div
                                    class="btn btn-danger btn-sm"
                                    (click)="this.selectedCertificate!.courseBundles.splice(i, 1)"
                                ><i class="ri-delete-bin-fill"></i></div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="text-center">
                        <div
                            class="btn btn-primary btn-sm"
                            (click)="this.selectedCertificate!.courseBundles.push([])"
                        ><i class="ri-add-line"></i></div>
                    </div>
                </div>
            </div>
            <div
                class="btn btn-primary float-end my-3"
                (click)="this.updateCertificateConfig(); newCockpitOverlay.unsetSelected()"
            >
                <span *ngIf="newCockpitOverlay.selectedObject.cert == 'new' else saveText">Create</span>
                <ng-template #saveText>Save</ng-template>
            </div>
        </ng-container>
    </div>
</rag-overlay-aside>

<table class="table table-striped">
    <thead>
        <tr>
            <th>Name</th>
            <th>Cert</th>
            <th>Course Bundles</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let config of this.configurations">
            <td>{{config.name}}</td>
            <td>{{config.certId}}</td>
            <td>
                <ng-container *ngFor="let courseBundle of config.courseBundles">
                    <div
                        class="badge bg-primary m-1"
                        [tooltip]="courseNamesTpl"
                        container="body"
                    >{{courseBundle.length}}</div>
                    <ng-template #courseNamesTpl>
                        <ul
                            *ngIf="courseBundle.length > 0"
                            class="list-group"
                        >
                            <li *ngFor="let course of getCourseNames(courseBundle)">{{course}}
                            </li>
                        </ul>
                        <ng-container *ngIf="courseBundle.length <= 0">
                            No Courses in this Bundle
                        </ng-container>
                    </ng-template>
                </ng-container>
            </td>
            <td>
                <div class="btn-group">

                    <div
                        class="btn btn-primary btn-small"
                        (click)="newCockpitOverlay.setSelected({'cert': config.certId}, 'cert'); this.selectedCertificate = config"
                    >
                        <i class="ri-edit-line"></i>
                    </div>
                    <div
                        class="btn btn-danger btn-small"
                        (click)="this.deleteCertificateConfig(config.certId)"
                    >
                        <i class="ri-delete-bin-fill"></i>
                    </div>
                </div>
            </td>

        </tr>
    </tbody>
</table>