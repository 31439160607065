import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CertificateConfig } from 'src/app/views/dashboard/dashboard.component';


@Injectable({
  providedIn: 'root'
})
export class AlmService {
  constructor(private httpClient: HttpClient) { }

  public async getAllUsers() {
    return await firstValueFrom(this.httpClient.get('/api/debug/user/getAllUsers'));
  }

  public async getEnrollments(userId: string, type?: string) {
    return await firstValueFrom(this.httpClient.get('api/debug/user/getEnrollments/' + userId + '/' + type));
  }

  public async deepload(loid: string) {
    return await firstValueFrom(this.httpClient.get('/api/debug/learningobject/deepload/' + loid));
  }

  public async updateUserModuleGrade(userId: string, courseId: string, moduleId: string) {
    return await firstValueFrom(this.httpClient.get('/api/debug/users/updateUserModuleGrade/' + userId + '/' + courseId + '/' + moduleId));
  }

  public async getLearningObjects(type: "certification" | "course"): Promise<any[]> {
    return await firstValueFrom(this.httpClient.get<any[]>('/api/debug/learningobject/' + type));
  }

  public async getCertificateConfigs() {
    return await firstValueFrom(this.httpClient.get<CertificateConfig[]>('/api/certificationadmin/configurations'));
  }

  public async updateCertificateConfig(config: any) {
    return await firstValueFrom(this.httpClient.post('/api/certificationadmin/configurations/update', config));
  }

  public async deleteCertificateConfig(certificateId: string) {
    return await firstValueFrom(this.httpClient.delete('/api/certificationadmin/configurations/delete/' + certificateId));
  }
}
